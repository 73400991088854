
import {
  SystemProduct,
  ProductTypes,
} from "@/store/modules/product/product.types";
import { Pagination } from "@/types/types";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const productX = namespace("Product");

@Component({})
export default class ProductSelect extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public outlined?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public hideDetails?: boolean;
  @Prop() public rules?: unknown[];
  @Prop() value?: number;

  @productX.Action(ProductTypes.LOAD_PRODUCTS)
  public getProducts!: (pagination: Pagination) => void;

  @productX.State(ProductTypes.PRODUCTS)
  public products!: SystemProduct[];

  @productX.State(ProductTypes.LOADING_PRODUCTS_STATE)
  public loadingProducts!: boolean;

  @Emit("input")
  save(id: string): string {
    return id;
  }

  mounted(): void {
    this.getProducts({ page: 1, limit: 10 });
  }
}
